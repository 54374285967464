import { useToast } from 'vue-toastification';
import ToastComponent from '~/components/ui/ToastComponent.vue';
import type { OrderDetails, OrdersListResponse } from '~/types/NewTypes/Profile';
import type { ReviewListResponse } from '~/types/NewTypes/Review';
import type { UserDetails, UserDetailsForm } from '~/types/User';
import type { SelectedDeliveryAddress } from '~/types/Checkout';

export interface Discounts {
  id: number;
  title: string;
  money_threshold: number;
  discount_percent: number;
  created_at: string;
  updated_at: string;
  sort_order: number;
}

export const useProfileStore = createProfileStore();

function convertFileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function createProfileStore() {
  return defineStore('profile', {
    state: () => ({
      userDetails: null as UserDetails | null,
    }),

    actions: {
      clearUserDetails() {
        this.userDetails = null;
      },

      async getUserInfo() {
        try {
          const { data } = await useCustomFetch<{ data: UserDetails | null }>('/profile/info');

          if (data.value && data.value.data) {
            this.userDetails = data.value.data;
          } else {
            this.userDetails = null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async updateUserInfo(userDetailsForm: UserDetailsForm) {
        try {
          const form: any = {};

          for (const [key, value] of Object.entries(userDetailsForm)) {
            if (value) {
              if (key === 'avatar') {
                form[key] = await convertFileToBase64(value);
              } else {
                form[key] = value;
              }
            }
          }

          const { data, error } = await useCustomFetch<{ data: UserDetails }>('/profile/info', {
            method: 'POST',
            body: form,
          });

          const toast = useToast();

          if (data.value?.data) {
            this.userDetails = data.value.data;
            toast.success({ component: ToastComponent, props: { text: 'Данные успешно обновлены' } });
            return data.value.data;
          }
          if (error.value) {
            toast.error({ component: ToastComponent, props: { text: error.value.data.message } });
            return false;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async getOrders() {
        try {
          const { query } = useRoute();

          const { data } = await useCustomFetch<{ data: OrdersListResponse }>('/profile/orders', { query });

          if (data.value?.data) {
            if (import.meta.client) setTimeout(() => window.scrollTo(0, 0), 10);

            return data.value.data;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getOrderById(orderId: string) {
        try {
          const { data } = await useCustomFetch<{ data: OrderDetails }>(`/profile/orders/${orderId}`);

          if (data.value?.data) {
            return data.value.data;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getOrderByUri(orderUri: string) {
        try {
          const { data } = await useCustomFetch<{ data: OrderDetails }>(`/profile/order/${orderUri}`);

          if (data.value?.data) {
            return data.value.data;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getPaymentStatus(orderId: string) {
        try {
          await useCustomFetch(`/payments/order/process/${orderId}`);
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },

      async getAddress() {
        try {
          const { data } = await useCustomFetch<SelectedDeliveryAddress>('/profile/address');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async saveAddress(address: SelectedDeliveryAddress) {
        try {
          const { data, error } = await useCustomFetch('/profile/address', { method: 'POST', body: address });

          const toast = useToast();

          if (data.value) {
            toast.success({ component: ToastComponent, props: { text: 'Данные успешно обновлены' } });
            return true;
          }
          if (error.value) {
            toast.error({ component: ToastComponent, props: { text: error.value.data.message } });
            return false;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getUserReviews() {
        try {
          const { data } = await useCustomFetch<ReviewListResponse>('/profile/testimonials');

          if (data.value) {
            return data.value;
          } else {
            return null;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
          return null;
        }
      },

      async getDiscountData() {
        try {
          const { data } = await useCustomFetch<Discounts[]>('/user-discount-thresholds');

          if (data.value) {
            return data.value;
          }
        } catch (e) {
          if (e && process.env.NODE_ENV !== 'production') {
            console.error(e);
          }
        }
      },
    },
  });
}
